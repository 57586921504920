// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

@tailwind base;
@tailwind components;
@tailwind utilities;

.content{min-height: calc(100vh - 2.5rem)!important;}

.align-center{align-items: center;}

div[x-data="LivewireUIModal()"]{z-index: 9999999;}
div[x-data="LivewireUIModal()"] .w-full.bg-white{max-width: 50vw; width: initial}


.documents-grid .side-menu-item:hover,
.documents-grid .side-menu-item.active{background: $hover-color; color: var(--white)}


@media (prefers-color-scheme: dark) {
    .dark\:text-white{color: $table-text-color !important;}
}

.data-table{
    table{
        border-collapse:separate;
        border-spacing:0 5px;
    }
    tbody{
        -webkit-border-vertical-spacing: 10px;
    }
    .filter_row td{
        background: rgb(var(--color-slate-100)/var(--tw-bg-opacity)) !important;
    }
}
